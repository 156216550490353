@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background-color: #EFF2F4;
  margin: 0;
  padding: 0;
  color: #333333;
  font-family: 'Poppins' !important;
  text-decoration: none;
  overflow-x: hidden !important;
}

a {
  color: #1570EF;
  text-decoration: none;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-end;
}

.preco {
  font-size: 16px;
  font-weight: 500;
}

.provas-sociais {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.user-social {
  display: flex;
  justify-content: left;
  align-items: center;
}

.user-social img {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
}

.etapa-completa {
  opacity: 1;
}

.p-social {
  display: flex;
  flex-direction: column;
  border: solid 2px #E6E7E7;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin: 10px;
}

.p-social img {
  width: 100%;
}

.textarea {
  font-size: 15px;
  font-weight: 500;
}

.icone_edicao {
  display: none;
  cursor: pointer;
}

/**** POP UP DE CARREGAMENTO - PRÓXIMA ETAPA FINAL *****/
.loading-spinner {
  border: 6px solid #ffffff;
  border-top: 6px solid #b6974f;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-top: 30vh;
}

.pop_up_loading {
  width: 100%;
  height: 100%;
  background-color: #212121d4;
  display: block;
  position: fixed;
  margin: 0;
  padding: 0;
  animation: opacidade 2s;
  z-index: 99;
  text-align: center;
}

.pop_up_loading p {
  color: white;
  margin-top: 10px;
  font-size: 17px;
  letter-spacing: 1px;
  animation: pulso 2s linear infinite;
}

@keyframes pulso {
  0% {
    color: white;
  }

  50% {
    color: rgba(255, 255, 255, 0.432);
  }

  100% {
    color: white;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**** POP UP DE ENTRADA - AVISO *****/
@media (max-width: 600px) {
  .content_pop_up {
    width: 285px !important;
  }
}

.pop_up {
  width: 100%;
  height: 100%;
  background-color: #21212178;
  display: none;
  position: fixed;
  margin: 0;
  padding: 0;
  animation: opacidade 2s;
  z-index: 99;
  text-align: center;
}

.content_pop_up {
  display: block;
  margin: auto auto;
  width: 500px;
  padding: 20px;
  margin-top: 80px;
  background: white;
  top: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
  border-radius: 8px;
}

@keyframes opacidade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.btn_redirect {
  margin: auto auto;
  padding: 10px;
  width: 150px;
  background-color: #FE450B;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.btn_redirect:hover {
  background-color: #c5390f;
}

.btn_fechar {
  margin-top: -15px;
  margin-right: 0 !important;
  float: right;
  font-size: 20px;
  cursor: pointer;
}


/**** PARTE DO CONTADOR *****/
.contador {
  color: #898792;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  max-width: 100%;
  background: #70A5D3;
  box-shadow: 0 0 2px 0 hsl(0deg 0% 80% / 50%);
}

.container-contador {
  margin: auto auto;
  height: 50px;
  width: 1040px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.logo {
  font-weight: 700;
}

.logo img {
  width: auto;
}

.contador .destaque {
  margin-left: 10px;
  color: white;
  font-weight: 500;
  border-radius: 20px;
  border: solid 1px white;
  padding: 2px 20px 2px 20px;
}

.pagamento img {
  width: 205px;
}

/**** PARTE DO CHECKOUT PC *****/
.view-pc {
  display: block;
}

.view-mobile {
  display: none;
}

.etapas-check {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.estrutura-check-pc {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  width: 1050px;
  margin: 25px 0px 20px 0px;
  align-items: flex-start;
  gap: 45px;
}

.bloco-esq {
  width: 100%;
  order: 1;
  border: none;
  box-shadow: 0px 4px 14px rgba(111, 127, 136, 0.12);
  border-radius: 8px;
  margin-bottom: 0px;
  background: #FFFFFF;
  padding: 10px;
}

.bloco-dir {
  order: 2;
  border: none;
  -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  border-radius: 8px;
  margin-bottom: 30px;
  background: #FFFFFF;
  padding: 10px;
}

.estrutura-check-fixa {
  width: 25%;
  margin: 45px 10px 20px 10px;
}

.estrutura-pc-inputs {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 300px;
  margin: auto auto;
  align-content: center;
  align-items: center;
}
.segundatela .estrutura-pc-inputs{
  width: 495px;
}
.group-input-pc {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

input{
  height: 16px !important;
  font-size: 14px !important;
  font-family: 'Poppins' !important;
}
.group-input-pc p{
  font-family: 'Poppins';
  margin-top: 5px;
}
.coluna2{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.MuiMenuItem-root{
  font-size: 14px !important;
  font-family: 'Poppins' !important;
}
.MuiSelect-select{
  font-size: 14px !important;
  font-family: 'Poppins' !important;
}
.forma_pagamento_title p{
  margin-left: -18px;
  margin-top: 2px;
  margin-bottom: 5px;
  font-weight: 500;
}
#primeira_etapa p{
  margin-top: 0;
  font-size: 15px;
}
/* Define a animação */
@keyframes aparecer {
  0% {
    border: solid 0px;
  }

  10% {
    border: solid 2px #ff00004d;
  }

  90% {
    border: solid 2px #ff00004d;
  }

  100% {
    border: solid 0px;
  }
}

.group-input-pc label {
  text-align: left !important;
  display: block;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: 'Poppins';
  line-height: 16px;
  margin-bottom: 5px;
}

::placeholder {
  color: #556971;
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-size: 15px;
  padding-left: 10px;
}

.sem-hover {
  pointer-events: none;
}

.group-input-pc select {
  border: none;
  background: #EFF2F4;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Poppins' !important;
  font-size: 15px;
}

.span_error {
  margin-top: 10px;
  font-family: 'Poppins' !important;
  font-size: 14px;
  color: gray;
  line-height: 1.3;
}

.alert_error {
  display: none;
  text-align: left;
  padding-bottom: 10px;
  font-family: 'Poppins' !important;
  font-size: 14px;
  color: red;
  line-height: 1.3;
  width: 300px;
  margin: auto auto;
}

/**** PARTE DO PROGRESSO *****/
#etapa01 {
  display: block;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: none !important;
}

#etapa02 {
  opacity: 0.6;
  border-radius: 10px;
  margin-bottom: 30px;
}

#etapa03 {
  opacity: 0.6;
  border-radius: 10px;
  margin-bottom: 30px;
}

#resumo {
  opacity: 0.6;
}

.estrutura-etapas {
  display: none;
  margin-top: 25px !important;
  width: 70%;
}

.circulo {
  width: 9px;
  height: 9px;
  line-height: 0.6;
  padding: 10px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border-radius: 50px;
}

.linha-progresso {
  margin-left: -45px;
  margin-right: -45px;
  margin-top: 16px;
  width: 100px;
  height: 1px;
  border-radius: 10px;
}

.progresso {
  width: 50%;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.progresso p {
  color: #666;
  font-size: 13px !important;
  line-height: 13px !important;
  margin-top: 3px;
  margin-bottom: 0px;
  width: 120px;
}

/**** BLOCO DE RESUMO DO PRODUTO *****/
.resumo-geral h3 {
  font-size: 18px;
  margin-left: 10px;
  font-weight: 500;
  padding: 0;
}

.linha_resumo {
  width: 100%;
  border-top: solid 1px #dfdfdf;
  margin-bottom: 10px;
  margin-top: 10px;
}

#input19 {
  margin-bottom: -10px !important;
}

/**** ESTRUTURA DO CHECKOUT *****/
#parte-etapas-mobile {
  display: none !important;
}

select {
  font-size: 17px;
}

.estrutura-check {
  width: 22%;
  margin: 20px;
  background: #FFFFFF;
  border: solid 2px #E6E7E7;
  border-radius: 8px;
  padding: 20px;
}

.etapa-check {
  height: 35px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  background: none;
  margin-bottom: 13px;
}

.numero {
  background-color: #70A5D3;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-weight: 600;
  color: white;
  text-align: center;
  line-height: 25px;
}

.etapa-check .check1 {
  background: #725BC2;
  padding: 10px;
  height: 8px;
  color: white;
  font-weight: bold;
  border-radius: 100%;
  line-height: 10px;
}

.etapa-check h2 {
  color: #1a1835;
  padding: 0;
  margin-bottom: 0px;
  margin-top: 0;
  margin-left: 10px;
  font-weight: 600;
  font-size: 17px;
}

.paragrafo {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  color: #001A21;
  margin-left: 10px;
  font-size: 16px;
  line-height: 18px;
}

.group-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.botao-form {
  text-align: center;
  display: block;
  margin: auto auto;
  background-color: #00a85f;
  color: white;
  font-size: 16px;
  font-weight: 400;
  width: 95%;
  padding: 10px 5px 10px 5px;
  border: none;
  border-radius: 90px;
  transition: ease-out 0.2s;
  margin-bottom: 20px;
  font-family: 'Poppins';
}

.botao-form:hover {
  cursor: pointer;
  background-color: #027c47;
}

.botao-form-alterar {
  text-align: center;
  margin: auto auto;
  background-color: white;
  color: #1570EF;
  font-size: 16px;
  font-weight: 400;
  width: 95%;
  padding: 10px 5px 10px 5px;
  border: 1px solid #1570EF;
  border-radius: 90px;
  transition: ease-out 0.2s;
  margin-bottom: 20px;
  font-family: 'Poppins';
}

.botao-form-alterar:hover {
  cursor: pointer;
  background-color: #1570EF;
  color: white;
}

.botao-form-desativado {
  text-align: center;
  display: block;
  margin: auto auto;
  background-color: #E1E6EA !important;
  color: #494a4a !important;
  font-size: 16px;
  font-weight: 400;
  width: 95%;
  padding: 10px 5px 10px 5px;
  border: none;
  border-radius: 90px;
  transition: ease-out 0.2s;
  margin-bottom: 20px;
  font-family: 'Poppins';
}


.botao-form p {
  color: gray;
  font-size: 14px;
  text-align: center;
}

.botao-form-voltar p {
  color: gray;
  font-size: 14px;
  text-align: center;
}

.botao-form-voltar button {
  margin-top: 10px;
  background-color: #939393;
  color: white;
  font-size: 15px;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  transition: ease-out 0.2s;
}

.botao-form-voltar button:hover {
  cursor: pointer;
  background-color: #505050;
}



/**** Opções de pagamento *****/

.detalhes-finais {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;
  margin: 10px;
  margin-bottom: 20px;
  line-height: 1.2;
}

.content-resumo {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  padding: 12px 0px 12px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;

}

.content-resumo p {
  color: #212121;
  font-size: 15px;
  text-align: right;
}

.content-resumo b {
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}

.imagem_produto {
  display: flex;
  gap: 18px;
  align-items: center;
}

.imagem_produto img {
  border-radius: 8px;
  width: 100%;
}

.imagem_produto h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #0C1727;
}

.titulo-resumo {
  width: 182px;
  display: flex;
  align-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
}
.titulo-resumo img{
  width: 20px;
  margin: 0;
  margin-right: 5px;
}

.titulo-total {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #0C1727;
}

.titulo-total-preco {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px !important;
  color: #0C1727 !important;
}
.titulo-total-preco p{
  margin-top: 2px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px !important;
  color: gray !important;
}
.preco_parcelado_resumo{
  margin-top: 20px;
}

.conteudo-boleto{
  margin-bottom: 15px;
}
.conteudo-boleto p {
  text-align: center;
  width: 300px;
  margin: auto auto;
  color: gray;
  font-size: 15px;
}

.option-pag {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0px;
  font-size: 18px;
}

.itens_pagamento {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 0px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.btn_opcao {
  gap: 7px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 20px;
  width: 100%;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: flex-start;
}

.btn_opcao input[type="radio"] {
  transform: scale(1.2);
}

.btn_opcao img {
  width: 30px;
}

.btn_opcao_ativo {
  color: #1570EF;
  cursor: pointer;
}

.btn_opcao:hover {
  color: #2781ff;
  cursor: pointer;
}

.explica-metodo {
  width: auto;
  display: flex;
  background-color: #fbfbfb;
  border: 1px solid #eee;
  padding: 1px 15px;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.content-explicametodo p {
  display: block;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 400;
}

.data_expiracao {
  display: flex;
  gap: 10px;
}

.data_expiracao input {
  width: 83% !important;
}

.inputoption {
  display: flex;
  line-height: normal;
  align-items: center;
}

.option-pag input::radio {
  width: 20px;
  height: 15px;
}

.selecao-frete {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
}

.input-option356 {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-option356:after {
  content: "";
  clear: both;
}

.radio {
  box-sizing: border-box;
  position: relative;
}

.radio+.radio {
  margin-left: 25px;
}

.radio label {
  display: flex;
  flex-direction: column;
  border: solid 2px #E6E7E7;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;

}

.radio p {
  font-size: 15px;
  font-weight: 400;
}

.radio label span {
  z-index: 1;
}

.radio label input[type=radio] {
  all: unset;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.radio label input[type=radio]:checked {
  border-radius: 5px;
  margin-bottom: 0px;
  padding: 0px;
  border: solid 3px #3583c9;
}
.botao_tentar_novamente {
  margin: auto auto;
  display: block;
  width: 150px;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff; /* Cor de fundo azul */
  color: white; /* Texto branco */
  text-align: center;
  text-decoration: none; /* Remove o sublinhado dos links */
  border-radius: 5px; /* Bordas arredondadas */
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
}

.botao_tentar_novamente:hover{
  background-color: #0057b5;
}
/*** Contador de quantidade *****/
.estrutura_inputs_qtd {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 300px;
  margin: auto auto;
  align-content: center;
  align-items: flex-start;
}
.group_qtd {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-left: 8px;
}

.group_qtd label {
  text-align: left !important;
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #1A1835;
  margin-bottom: 5px;
}

.contador_quantidade {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  font-size: 16px;
}

.btn_quantidade {
  cursor: pointer;
  background-color: #EFF2F4;
  color: black;
  padding: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: ease-in-out .2s;
  font-weight: 500;
}

.btn_quantidade:hover {
  background-color: #1570EF;
  color: white;
}
/**** Parte do cupom *****/
.cupom{
  margin: auto auto;
  text-align: left;
  font-size: 14px;
  color: #1A1835;
  margin-bottom: 0px;
}
.parte_cupom p{
  margin-top: -8px;
  color: gray;
  font-size: 14px;
}
.group_input_cupom{
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.botao_cupom {
  cursor: pointer;
  text-align: center;
  display: block;
  margin: auto auto;
  background-color: #496781;
  color: white;
  font-size: 15px;
  font-weight: 400;
  width: 80px;
  padding: 10px 12px 10px 12px;
  border: none;
  border-radius: 90px;
  transition: ease-out 0.2s;
  font-family: 'Poppins';
}
.botao_cupom:hover{
  background-color: #32485c;
}

/**** Opções de pagamento - Cartão *****/
.modal-cartao {
  border: solid 2px #E6E7E7;
  border-radius: 10px;
  padding: 20px;
}

.modal-cartao .group-input-cartao {
  display: flex;
  margin-bottom: 12px;
}

.gp-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.gp-input input {
  width: 85%;
  border: solid 1px #B3B3B3;
  padding: 10px;
  border-radius: 5px;
}

.gp-input select {
  margin-top: 0;
  width: 85%;
  border: solid 1px #B3B3B3;
  padding: 10px;
  border-radius: 5px;
}

.gp-input label {
  font-size: 15px;
}

#modalcartao {
  display: none;
}

#modalpix {
  display: block;
}

#modalboleto {
  display: none;
}


.modalpix img {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

.modalpix p {
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding-bottom: 20px;
}

.modalboleto {
  border: solid 2px #E6E7E7;
  border-radius: 10px;
  padding: 20px;
}

.modalboleto img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

.modalboleto p {
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}